var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"item-key":"id","disable-sort":"","server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'textWithFiles')?_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.website.config.participantZone.list.textWithFiles')))]):_c('span',[_vm._v("HTML")])]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [(item.groups && item.groups.length)?_vm._l((item.groups),function(group){return _c('v-chip',{key:group.id,staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(group.name))])}):_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.website.config.participantZone.list.infoForAll')))])]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [(item.files && item.files.length)?_c('span',[_vm._v(_vm._s(item.files.length))]):_c('span',[_vm._v("---")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.details')))])])]}}],null,true),model:{value:(item.detailsDialog),callback:function ($$v) {_vm.$set(item, "detailsDialog", $$v)},expression:"item.detailsDialog"}},[_c('m-participant-zone-details',{attrs:{"item":item},on:{"close":function($event){item.detailsDialog = false}}})],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])])]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }